@media only screen and (max-width: 425px) {
  .promo-neo-pop {
    display: none;
  }

  .scrollable {
    width: 100vw;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 24px 14px;
    margin-top: 100px;
    position: relative;

    .bnpl-container {
      // margin-top: 4px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      width: 117px;
      height: 26px;

      background: linear-gradient(
        93.7deg,
        rgba(83, 63, 108, 0.4) 46.96%,
        rgba(57, 33, 87, 0.4) 94.51%
      );
      border-radius: 16.2011px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 9.72067px;
      line-height: 15px;
      /* identical to box height */

      letter-spacing: 0.01em;

      color: #ffffff;
    }
  }

  .mobile-header {
    background-color: #080210;
    position: fixed;
    z-index: 1000;
    height: 82px;
    width: 100vw;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 24px 0px;

    .customer-drop-down {
      border-width: 0;
      z-index: 100;

      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 126.5px;
      height: 35px;
      z-index: 100;
      right: 10px;
      top: -16px;
      padding: 4px 12px;

      border-radius: 10px;
      background: var(--1, linear-gradient(147deg, #238148 0%, #4ee089 100%));

      p {
        letter-spacing: 0.01em;
        color: #fff;
        font-size: 15px;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        letter-spacing: 0.15px;
      }
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 12.1349px;
      line-height: 18px;

      color: #ffffff;
      z-index: 10;
    }

    img {
      height: 36.4px;
      width: 36.4px;
    }

    .zap {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 28.415px;
      line-height: 43px;
      /* identical to box height */

      background: linear-gradient(
        102.08deg,
        #a5fd00 41.19%,
        #4ee089 55.5%,
        #238148 66.91%,
        #87ffb7 84.14%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  .video-header {
    margin-top: 100px;
    font-size: 24px;
    color: #fff;
    text-align: center;
  }

  .video-desc {
    font-size: 16px;
    width: 90vw;
    align-self: center;
    color: #fff;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 426px) {
  .scrollable,
  .mobile-header {
    display: none;
  }

  .cursor {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    position: fixed;
    pointer-events: none;
    background-color: #fff;
    left: 0;
    top: 0;
    mix-blend-mode: difference;
  }

  .video-header {
    margin-top: 162px;
    font-size: 38px;
    color: #fff;
    text-align: center;
  }

  .video-desc {
    font-size: 24px;
    color: #fff;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.customer-container {
  width: 100vw;
  display: flex;
  flex-direction: column;

  .promo-neo-pop {
    align-self: center;
    background-color: #fff;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-top: 100px;
    margin-bottom: 100px;

    .promo-neo-pop-inner {
      background: radial-gradient(
        62.98% 1332.04% at 26.75% 39.17%,
        #6f00fd 0%,
        #522191 100%
      );
      margin-left: -10px;
      margin-top: -10px;
      padding: 20px 40px;

      h2 {
        font-family: "Space Mono", monospace;
        font-style: normal;
        font-weight: 700;
        font-size: 31.1827px;
        line-height: 46px;
        text-align: center;
        letter-spacing: -0.05em;
        color: #fff;
      }
    }
  }
}
