#participants-companies {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;

  h1 {
    font-size: 5rem;
  }

  .container {
    border-radius: 20.351px;
    background: linear-gradient(
      90deg,
      #d735ff 0%,
      #8729ff 55.73%,
      #915fff 95.31%
    );
    padding: 150px 40px;

    h3 {
      color: #fff;
      text-align: center;
      font-family: "Poppins";
      font-size: 61.388px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .participants {
      display: flex;
      margin-top: 80px;
      gap: 2rem;

      img {
        width: 166.266px;
        height: 166.266px;
        flex-shrink: 0;
      }
    }
  }
}
