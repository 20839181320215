.App {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}

.horizontal {
  display: flex;
  align-items: center;
}

.gradient-text {
  background: linear-gradient(
    153deg,
    #a5fd00 0%,
    #4ee089 33.33%,
    #238148 59.9%,
    #87ffb7 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-text {
  flex-shrink: 0;
  text-align: center;
  font-family: Lexend;
  font-size: 29.418px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.gradient-text-secondary {
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
