#customer-why {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;
  gap: 50px;
  // padding: 40px;

  .why-content {
    display: flex;
    gap: 4rem;

    .top-container {
      border: #fff 1px solid;
      border-radius: 31.544px;
      background: linear-gradient(
        134deg,
        rgba(87, 167, 110, 0.33) 0%,
        rgba(40, 108, 64, 0.26) 66.14%,
        rgba(16, 79, 41, 0.22) 100%
      );

      width: 25vw;
      height: 27vw;
      flex-shrink: 0;

      .container {
        width: 100%;
        height: 100%;
        flex-shrink: 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 24px;

        border-radius: 31.544px;
        background: linear-gradient(
          117deg,
          rgba(87, 167, 110, 0.33) 0%,
          rgba(40, 108, 64, 0.26) 30%,
          rgba(16, 79, 41, 0.22) 43%
        );

        img {
          width: calc(25vw * 0.4);
          height: calc(25vw * 0.4);
        }

        h3 {
          color: #f1f0f0;
          font-family: "Lexend";
          font-size: calc(25vw * 0.075);
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        p {
          margin-top: 8px;
          color: #fff;
          text-align: center;
          font-family: "Poppins";
          font-size: calc(25vw * 0.05);
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
      }
    }
  }
}
