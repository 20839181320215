@media only screen and (max-width: 425px) {
  .header-container {
    display: none;
  }

  .switch-component {
    display: none;
  }
}

@media only screen and (min-width: 425px) {
  .item {
    border-radius: 20px;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      background: linear-gradient(147deg, #238148 0%, #4ee089 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      z-index: 2000;
      transition: all 0.2s ease-in-out;
    }
  }

  .selected-item {
    p {
      -webkit-text-fill-color: #fff;
      text-decoration: none;
      z-index: 2000;
      text-align: center;
      transition: all 0.2s ease-in-out;
    }
  }

  .header-container {
    height: 100px;
    width: 100vw;
    background-color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vw;
    z-index: 1000;

    // background-color: transparent;
    // backdrop-filter: blur(10px);
    // -webkit-backdrop-filter: blur(10px);

    // background: radial-gradient(
    //   111.89% 213.6% at 24.99% 43.36%,
    //   rgba(130, 130, 130, 0.2) 0%,
    //   rgba(223, 226, 255, 0.1) 50.98%,
    //   rgba(0, 0, 0, 0.2) 100%
    // );

    .logo-caption {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;

      background: linear-gradient(
        102.08deg,
        #a5fd00 41.19%,
        #4ee089 55.5%,
        #238148 66.91%,
        #87ffb7 84.14%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      width: 100px;
      margin-right: 10px;
    }

    .logo {
      width: 80px;
      height: 80px;
      position: relative;
    }

    .logo-container {
      background: linear-gradient(
        102.08deg,
        #a5fd00 41.19%,
        #4ee089 55.5%,
        #238148 66.91%,
        #87ffb7 84.14%
      );
      left: 25px;
      top: 24px;
      width: 30px;
      position: absolute;
      height: 30px;
    }

    #change {
      width: 235px;
      height: 63px;

      background: #ffffff;
      border-radius: 20px;
      border-width: 0;

      font-family: "Space Grotesk", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      /* identical to box height */

      text-align: center;
      letter-spacing: -0.05em;

      color: #701dda;
    }

    // .switch-component {
    //   width: 510px;
    //   height: 64px;
    //   border-radius: 20px;
    //   background: #373737;
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    //   justify-content: space-between;
    //   position: relative;

    //   .item {
    //     border-radius: 20px;
    //     width: 235px;
    //     height: 64px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;

    //     p {
    //       color: #fff;
    //       text-align: center;
    //       font-size: 20px;
    //       font-family: "Space Grotesk", sans-serif;
    //       font-weight: 700;
    //       letter-spacing: -1px;
    //       text-decoration: none;
    //       z-index: 2000;
    //       transition: all 0.2s ease-in-out;
    //     }
    //   }

    //   .selected-item {
    //     // background: #fff;

    //     p {
    //       text-decoration: none;
    //       z-index: 2000;
    //       color: #701dda;
    //       text-align: center;
    //       font-size: 20px;
    //       font-family: "Space Grotesk", sans-serif;
    //       font-weight: 700;
    //       letter-spacing: -1px;
    //       transition: all 0.2s ease-in-out;
    //     }
    //   }
    // }
  }
}
