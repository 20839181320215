.bnpl-container {
  border-radius: 24px; /*1*/
  border: 1px solid transparent; /*2*/
  background: linear-gradient(
      200deg,
      rgba(255, 124, 0, 1) -20%,
      rgba(255, 200, 55, 1)
    )
    border-box; /*3*/

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  box-shadow: 0px 0px 16px 6px rgba(255, 124, 0, 0.5);

  margin-bottom: 30px;
  border-radius: 24px;
}
