@media only screen and (min-width: 426px) {
  .faq-container-mob {
    display: none;
  }
}

@media only screen and (max-width: 425px) {
  .faq-container {
    display: none;
  }

  .faq-container-mob {
    margin: 44px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 106.5%;
      /* or 22px */

      display: flex;
      align-items: center;
      text-align: center;

      /* Zap */

      background: linear-gradient(
        102.08deg,
        #a5fd00 41.19%,
        #4ee089 55.5%,
        #238148 66.91%,
        #87ffb7 84.14%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;

      margin-bottom: 22px;
    }

    button {
      min-height: 40px;
      margin-top: 22px;
      width: 90vw;

      background: #000000;
      border-width: 0;
      border-radius: 6px;

      padding: 11px 16px;

      .horizontal {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      p {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 500;
        font-size: 14.9193px;
        line-height: 107.18%;

        text-align: left;
        /* identical to box height, or 16px */

        display: flex;
        align-items: center;

        color: #ffffff;
      }

      .ans {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 140.68%;
        margin-top: 20px;
        /* or 17px */

        display: flex;
        align-items: center;

        color: #fff;
      }
    }
  }
}

.faq-container {
  width: 100vw;
  padding: 110px 48px;
  background: #000;

  h1 {
    font-family: "Lexend";
    font-style: normal;
    font-weight: 900;
    font-size: 70px;

    background: linear-gradient(
      102.08deg,
      #a5fd00 41.19%,
      #4ee089 55.5%,
      #238148 66.91%,
      #87ffb7 84.14%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .qa-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 44px;

    .question-container {
      width: 615.3px;
      height: 396.74px;
      border-radius: 10px;
      overflow-y: scroll;
      overflow-x: hidden;

      .question {
        border-width: 0;
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
        padding-left: 16px;
        padding-right: 40px;

        width: 615.3px;
        height: 72px;

        background: #000000;
        box-shadow: 0px 0px 0.96141px 0.96141px rgba(0, 0, 0, 0.15);

        .selected {
          width: 23.07px;
          height: 23.07px;
          border-radius: 20px;

          background: #2c8750;
        }

        .default {
          width: 23.07px;
          height: 23.07px;
          border-radius: 20px;

          /* Iris/60 */

          background: #a5a6f6;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 15.3826px;
          line-height: 23px;
          margin-left: 16px;
          /* or 150% */

          color: #ffffff;
        }

        .icon {
          position: absolute;
          right: 16px;
        }
      }
    }

    .question-container::-webkit-scrollbar {
      display: none;
    }
  }

  .faq-qa-container {
    background: linear-gradient(
      180deg,
      #238148 0%,
      rgba(78, 224, 137, 0.4) 100%
    );
    box-shadow: 0px 0px 3.84564px 0.96141px rgba(0, 0, 0, 0.15);
    border-radius: 15.3826px;
    height: 450px;
    width: 620px;
    margin-left: -60px;
    padding-left: 100px;
    padding-right: 60px;
    padding-top: 50px;

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 17.3054px;
      line-height: 27px;
      /* identical to box height, or 156% */

      font-feature-settings: "salt" on, "liga" off;

      color: #ffffff;
      margin-bottom: 30px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15.3826px;
      line-height: 23px;
      /* or 150% */

      color: #ffffff;
    }
  }
}
