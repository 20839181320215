@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Orelega+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  /* font-family: "Poppins", sans-serif; */
}

body::-webkit-scrollbar {
  display: none;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

button {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

button:hover {
  transform: scale(1.025);
}

/* @font-face {
  font-family: "Gotham";
  src: url("./fonts/GothamBlack.woff2") format("woff2"),
    url("./fonts/GothamBlack.woff") format("woff"),
    url("./fonts/GothamBold.woff2") format("woff2"),
    url("./fonts/GothamBold.woff") format("woff"),
    url("./fonts/GothamThin.woff2") format("woff2"),
    url("./fonts/GothamThin.woff") format("woff"),
    url("./fonts/GothamBook.woff2") format("woff2"),
    url("./fonts/GothamBook.woff") format("woff"),
    url("./fonts/GothamExtraLight.woff2") format("woff2"),
    url("./fonts/GothamExtraLight.woff") format("woff"),
    url("./fonts/GothamLight.woff2") format("woff2"),
    url("./fonts/GothamLight.woff") format("woff"),
    url("./fonts/GothamMedium.woff2") format("woff2"),
    url("./fonts/GothamMedium.woff") format("woff"),
    url("./fonts/GothamUltra.woff2") format("woff2"),
    url("./fonts/GothamUltra.woff") format("woff");
  font-style: normal;
  font-display: swap;
} */



@font-face {
  font-family: "GothamBold";
  src: url("./fonts/GothamBold.woff2") format("woff2"),
    url("./fonts/GothamBold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "GothamThin";
  src: url("./fonts/GothamThin.woff2") format("woff2"),
    url("./fonts/GothamThin.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "GothamBook";
  src: url("./fonts/GothamBook.woff2") format("woff2"),
    url("./fonts/GothamBook.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "GothamExtraLight";
  src: url("./fonts/GothamExtraLight.woff2") format("woff2"),
    url("./fonts/GothamExtraLight.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "GothamLight";
  src: url("./fonts/GothamLight.woff2") format("woff2"),
    url("./fonts/GothamLight.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "GothamMedium";
  src: url("./fonts/GothamMedium.woff2") format("woff2"),
    url("./fonts/GothamMedium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "GothamUltra";
  src: url("./fonts/GothamUltra.woff2") format("woff2"),
    url("./fonts/GothamUltra.woff") format("woff");
  font-display: swap;
}
