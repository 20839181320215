@media only screen and (max-width: 425px) {
  #customer-form {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .horizontal {
    display: none;
  }

  input {
    background-color: rgba(141, 141, 141, 0.2);
    // opacity: 0.7;
    height: 63.78px;
    border-width: 0;
    width: 100%;
    border-radius: 7.7781px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: 0.01em;
    margin-top: 16px;

    color: #ffffff;
    padding: 0 24px;
  }

  .vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-customer-container {
    width: 90vw;
    align-self: center;

    margin-top: -30px;
    padding-top: 30px;

    overflow: hidden;
    display: flex;

    .border {
      width: 90vw;
      padding: 20px;

      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      background: linear-gradient(
        94.2deg,
        #00000020 -0.02%,
        #4ee08940 48.75%,
        #10552c40 77.6%,
        #00000020 100.02%
      );

      border-image: linear-gradient(200deg, #00692a -20%, #fff) 1;
      border-width: 4px;
      border-style: solid;

      h3 {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.01em;

        color: #ffffff;
      }

      input {
        background-color: rgba(141, 141, 141, 0.2);
        // opacity: 0.7;
        height: 63.78px;
        border-width: 0;
        width: 100%;
        border-radius: 7.7781px;
        font-family: "Lexend";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 19px;
        letter-spacing: 0.01em;
        margin-top: 16px;

        color: #ffffff;
        padding: 0 24px;
      }

      .vertical {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      button {
        align-self: center;
        margin-top: 40px;
        background-color: #000;
        justify-content: center;
        align-items: center;
        display: flex;
        border-width: 0;
        height: 48px;
        width: 90%;
        border-radius: 8px;

        color: #fff;
      }
    }
  }
}

@media only screen and (min-width: 426px) {
  #customer-form {
    display: flex;
    flex-direction: column;
  }

  input {
    background-color: rgba(141, 141, 141, 0.2);
    // opacity: 0.7;
    height: 63.78px;
    border-width: 0;
    width: 20vw;
    border-radius: 7.7781px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: 0.01em;

    color: #ffffff;
    padding: 0 24px;
  }

  // button {
  //   align-self: center;
  //   margin-top: 40px;
  //   background-color: #000;
  //   justify-content: center;
  //   align-items: center;
  //   display: flex;
  //   border-width: 0;
  //   height: 48px;
  //   width: 350px;
  //   border-radius: 8px;

  //   color: #fff;
  // }

  .form-customer-container {
    // margin: 90px 0 120px;
    height: 420px;

    align-self: center;

    overflow: hidden;
    display: flex;
    border-radius: 17.3632px;

    .border {
      height: 420px;
      width: full;
      border-radius: 17.3632px;

      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      // background: linear-gradient(
      //   94.2deg,
      //   #00000020 -0.02%,
      //   #4ee08940 48.75%,
      //   #10552c40 77.6%,
      //   #00000020 100.02%
      // );

      // border-image: linear-gradient(200deg, #00692a -20%, #fff) 1;
      border-width: 10px;
      border-style: solid;

      h3 {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 700;
        font-size: 20.8359px;
        line-height: 26px;
        /* identical to box height */

        letter-spacing: 0.01em;

        color: #ffffff;
      }

      input {
        background-color: rgba(141, 141, 141, 0.2);
        // opacity: 0.7;
        height: 63.78px;
        border-width: 0;
        width: 20vw;
        border-radius: 7.7781px;
        font-family: "Lexend";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 19px;
        letter-spacing: 0.01em;

        color: #ffffff;
        padding: 0 24px;
      }

      button {
        align-self: center;
        margin-top: 40px;
        background-color: #000;
        justify-content: center;
        align-items: center;
        display: flex;
        border-width: 0;
        height: 48px;
        width: 350px;
        border-radius: 8px;

        color: #fff;
      }
    }
  }

  .vertical {
    display: none;
  }
}
