@media only screen and (max-width: 425px) {
  .how-it-works-container {
    display: flex;
    flex-direction: column;
    padding: 60px 0;

    h2 {
      font-family: "Lexend";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 33px;
      /* identical to box height */

      text-align: center;
      letter-spacing: -0.05em;

      /* Zap */

      background: linear-gradient(
        102.08deg,
        #a5fd00 41.19%,
        #4ee089 55.5%,
        #238148 66.91%,
        #87ffb7 84.14%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    h4 {
      font-family: "Space Mono", monospace;
      font-style: italic;
      font-weight: 700;
      text-align: center;
      margin-top: 16px;
      font-size: 16px;

      color: #ffffff;
    }

    .option-container {
      display: none;
    }

    .option-container-mob {
      padding-top: 30px;

      .vertical {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;

        .text-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 24px;

          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 24.0479px;
            line-height: 36px;
            /* identical to box height */

            text-align: center;
            letter-spacing: -0.05em;

            color: #6f00fd;
            margin-top: 24px;
          }

          p {
            font-family: "Lexend";
            font-style: normal;
            font-weight: 400;
            font-size: 16.4394px;
            line-height: 21px;
            text-align: center;
            letter-spacing: -0.05em;
            width: 80%;

            color: #d8d8d8;
          }

          .gradient {
            background: linear-gradient(
              102.08deg,
              #a5fd00 41.19%,
              #4ee089 55.5%,
              #238148 66.91%,
              #87ffb7 84.14%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-family: "Lexend";
            font-style: normal;
            font-weight: 700;
          }
        }
      }
    }

    .bottom-desc {
      margin-top: 70px;
      width: 90vw;
      font-size: 20px;
      line-height: 24px;
      align-self: center;
    }
  }
}

@media only screen and (min-width: 426px) {
  .how-it-works-container {
    display: flex;
    flex-direction: column;

    h2 {
      font-family: "Lexend";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      /* identical to box height */

      text-align: center;
      letter-spacing: -0.05em;

      /* Zap */

      background: linear-gradient(
        102.08deg,
        #a5fd00 41.19%,
        #4ee089 55.5%,
        #238148 66.91%,
        #87ffb7 84.14%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    h4 {
      font-family: "Space Grotesk", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      letter-spacing: -0.05em;

      color: #ffffff;
      margin-top: 30px;
      margin-bottom: 80px;
    }

    .option-container {
      align-self: center;
      width: 70vw;

      .text-container {
        width: 40vw;
      }

      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 47.2632px;
        line-height: 71px;
        /* identical to box height */

        text-align: center;

        color: #6f00fd;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 32.8788px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.05em;

        color: #ffffff;
      }

      .gradient {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 700;
        /* identical to box height */

        text-align: center;

        /* Zap */

        background: linear-gradient(
          102.08deg,
          #a5fd00 41.19%,
          #4ee089 55.5%,
          #238148 66.91%,
          #87ffb7 84.14%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }

  .option-container-mob {
    display: none;
  }
}
